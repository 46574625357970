import { MyApplicationComponent } from './my-application/my-application.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AppGuard } from './auth/guards/app.guard';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full'  },
  {
    path: '',
    canActivate: [AppGuard],
    component: FullLayoutComponent,
    children: [
        { path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) },
        { path: 'my-application', loadChildren:()=>import('./my-application/my-application.module').then(m=>m.MyApplicationModule)},
        { path: 'vacancies', loadChildren:()=>import('./vacancies/vacancies.module').then(m=>m.VacanciesModule)},
        { path: 'job-applications', loadChildren:()=>import('./Job-applications/job-applications.module').then(m=>m.JobApplicationsModule)},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
