<div class="bg-login">
	<!--wrapper-->
	<div class="wrapper">
		<div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
      <hr>
      <pre>{{ closeResult }}</pre>
			<div class="container-fluid">
				<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
					<div class="col mx-auto">
            <div class="card border-top border-0 border-4 border-grey">
              <div class="card-body px-5">
                <div class="card-title mt-3 mb-3" >
                  <div class="align-items-center">
                    <img src="assets/images/IRDO-1.png" class="logo-icon" alt="IRDO Logo">
                  </div>
                  <!-- <h5 class="mb-5 mt-2" style="color: #607C8A;">User Login</h5> -->
                </div>
                <hr>
                <div *ngIf="error" class="alert alert-danger" role="alert">
                  {{error}}
                  <button type="button" class="close" aria-label="Close" (click)="dismissAlert()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                 <form class="form-signin" [formGroup]="signinForm" autocomplete="off">
                  <div class="col-12">
                    <label for="inputLastEnterUsername" class="form-label">Enter Email Address</label>
                    <div class="input-group input-group-lg"> <span class="input-group-text bg-transparent"><i
                          class='bx bxs-user'></i></span>
                      <input type="text" class="form-control border-start-0" formControlName="emailAddress" [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }" placeholder="Enter your Email Address" id="inputLastEnterUsername"/>
                    </div>
                    <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="text-danger">
                      <div *ngIf="f.emailAddress.errors?.required">Email Address is required.</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label for="inputLastEnterPassword" class="form-label">Enter Password</label>
                    <div class="input-group input-group-lg"> <span class="input-group-text bg-transparent"><i
                          class='bx bxs-lock-open'></i></span>
                      <input type="password" class="form-control border-start-0" formControlName="password" [ngClass]="{ 'is-invalid': f.submitted && f.password.errors }"
                        placeholder="Enter Password" id="inputLastEnterPassword"/>
                    </div>
                    <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
                      <div *ngIf="f.password.errors?.required">Password is Empty.</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck3">
                      <label class="form-check-label" for="gridCheck3">Remember Me</label>
                    </div>
                  </div>
                  <div class="col-md-6 text-end"> <a routerLink="/forgot-password" (click)="$event.preventDefault()" id="link">Forgot Password ?</a>
                  </div>
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="button" [disabled]="loading" (click)="login()" [disabled]="!signinForm.valid" class="btn btn-md px-5 btn-primary"><i class='bx bxs-lock-open'></i>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                      </button>
                    </div>
                  </div>
                </form>
                  <hr />
                  <div class="col-12 text-center">
                    <a routerLink="/sign-up" class="mb-0" id="link">Don't have an Account? Sign Up</a>
                  </div>
                  <hr />
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--end wrapper-->
</div>
