interface Config {
  [key: string]: string;
  auth: 'session' | 'token';
}

// Session auth needs to use the same origin anyway
export const config: Config = {
  // apiUrl:'http://microservices.sysre.co.ke:4046/api',
  apiUrl:'http://careersapi.impact-rdo.org/api',
  auth: 'token'
};
