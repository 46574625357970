import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SigninWithHeaderFooterComponent } from './signin-with-header-footer/signin-with-header-footer.component';
import { SignupWithHeaderFooterComponent } from './signup-with-header-footer/signup-with-header-footer.component';

// const routes: Routes = [
//   {
//     path: '',
//     children: [
//       {
//         path: 'sign-in',
//         component: SignInComponent,
//         canActivate: [AuthGuard],
//         data: {
//           title: 'Sign In'
//         }
//       },
//       {
//         path: 'sign-up',
//         component: SignUpComponent,
//         canActivate: [AuthGuard],
//         data: {
//           title: 'Sign Up'
//         }
//       },
//       {
//         path: 'signin-with-header-footer',
//         component: SigninWithHeaderFooterComponent,
//         data: {
//           title: 'SignIn With Header Footer'
//         }
//       },
//       {
//         path: 'signup-with-header-footer',
//         component: SignupWithHeaderFooterComponent,
//         data: {
//           title: 'SignUp With Header Footer'
//         }
//       },

//       {
//         path: 'forgot-password',
//         component: ForgotPasswordComponent,
//         canActivate: [AuthGuard],
//         data: {
//           title: 'Forgot Password'
//         }
//       },
//       {
//         path: 'reset-password',
//         component: ResetPasswordComponent,
//         data: {
//           title: 'Reset Password'
//         }
//       },
//       {
//         path: 'lock-screen',
//         component: LockScreenComponent,
//         data: {
//           title: 'Lock Screen'
//         }
//       },
//     ]
//   }
// ];

const routes: Routes = [
      {
        path: 'sign-in',
        component: SignInComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sign In'
        }
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Sign Up'
        }
      },
      {
        path: 'signin-with-header-footer',
        component: SigninWithHeaderFooterComponent,
        data: {
          title: 'SignIn With Header Footer'
        }
      },
      {
        path: 'signup-with-header-footer',
        component: SignupWithHeaderFooterComponent,
        data: {
          title: 'SignUp With Header Footer'
        }
      },

      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Forgot Password'
        }
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
          title: 'Reset Password'
        }
      },
      {
        path: 'lock-screen',
        component: LockScreenComponent,
        data: {
          title: 'Lock Screen'
        }
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
