import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { PasswordService } from '../services/password.service.spec';
import { ConfirmedValidator } from '../sign-up/confirmed.validator';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  public isFinished: boolean;
  public emailAddress: string;
  public passwordForm: FormGroup;
  public recovery: boolean;
  private passwordResetToken: string;
  loading = false;


  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private passwordService: PasswordService) { }


	// On Login link click
	onLogin() {
	  this.router.navigate(['sign-in'], { relativeTo: this.activeRoute.parent });
	}


  ngOnInit(): void {
    this.isFinished = false;
    this.emailAddress = this.activeRoute.snapshot.queryParams.emailAddress;
    this.passwordResetToken = this.activeRoute.snapshot.queryParams.passwordResetToken;

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['']
    },
    {
      validator: ConfirmedValidator('password', 'confirmPassword')      }
  );}

  get f() { return this.passwordForm.controls; }

  setPassword() {
    this.loading = true;
    let task: Observable<void>;
    task = this.passwordService.recover(this.emailAddress, this.passwordResetToken, this.f.password.value, this.f.confirmPassword.value);
    task.subscribe(() => this.isFinished = true);
    this.loading = false;
  }

}
