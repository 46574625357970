import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CacheService } from './cache.service';
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
//import { AlertComponent } from './alert/alert.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './error.interceptor';
import { ForRolesDirective } from '@app/auth/directives/for-roles.directive';
import { AuthModule } from '@app/auth/auth.module';
// import { SectorsComponent } from './sectors/sectors.component';



@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        AuthModule,
        NgbModule,
        PerfectScrollbarModule
    ],
    exports: [
        CommonModule,
        ForRolesDirective,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NgbModule,
       // AlertComponent
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
      //  AlertComponent
    ],
    providers: [
      CacheService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
      }
    ],
})
export class SharedModule { }
