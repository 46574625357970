import { Component } from '@angular/core';
import { AuthService } from './auth/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private authService: AuthService
    ) { }

  // logout() {
  //   this.authService.logout()
  //     .subscribe(() => {
  //       this.router.navigate([this.authService.LOGIN_PATH]);
  //     });
  // }

}
